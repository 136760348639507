import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Client Login | Dark Horse CPAs"
        description="We have a number of tools we use that are important for each of our clients, and we want a single place that you can access everything you need from Dark Horse CPAs."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-50 pt-16 pb-24 md:pt-22 md:pb-40">
        <div className="container">
          <header className="mx-auto mb-14 text-center md:mb-20 md:max-w-[640px]">
            <h1>Client Login Portal</h1>
          </header>
          <div className="grid gap-8 md:grid-cols-2">
            <a
              href="https://darkhorsecpas.sharefile.com/Authentication/Login"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-4 py-6 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-6"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.taxPlanning.childImageSharp.gatsbyImageData}
                    className="h-10 w-10"
                  />
                </div>
                <div>
                  <div className="heading-five">Client Files</div>
                  <p className="mb-3.5">
                    Our secure client portal is where you'll find all of your
                    tax returns, documents, letters and statements.
                  </p>
                  <div className="space-x-1.5 text-sm font-semibold text-secondary-500 transition-colors duration-300 ease-linear group-hover:text-secondary-700">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://app.darkhorse.cpa/signin?redirect=%2F"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-4 py-6 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-6"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.intuit.childImageSharp.gatsbyImageData}
                    className="h-10 w-10"
                  />
                </div>
                <div>
                  <div className="heading-five">Tax Preparation Organizer</div>
                  <p className="mb-3.5">
                    Our dynamic Tax Organizer collects your current tax year’s document to get ready for filing.
                  </p>
                  <div className="space-x-1.5 text-sm font-semibold text-secondary-500 transition-colors duration-300 ease-linear group-hover:text-secondary-700">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://reports.darkhorse.cpa/login"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-4 py-6 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-6"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.reporting.childImageSharp.gatsbyImageData}
                    className="h-10 w-10"
                  />
                </div>
                <div>
                  <div className="heading-five">Reporting Dashboard</div>
                  <p className="mb-3.5">
                    A dashboard analytics overview of your financials and KPIs
                    for clients enrolled in our business reporting suite.
                  </p>
                  <div className="space-x-1.5 text-sm font-semibold text-secondary-500 transition-colors duration-300 ease-linear group-hover:text-secondary-700">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              href="https://app.qbo.intuit.com/app/login?loadCustomerAssistanceAssets=us&product=qbOnline"
              target="_blank"
              rel="noopener noreferrer"
              className="group rounded-4xl bg-white px-4 py-6 font-normal text-gray-700 no-underline shadow-4xl hover:text-gray-700 md:px-6"
            >
              <div className="flex items-start space-x-4">
                <div>
                  <GatsbyImage
                    image={data.quickbooks.childImageSharp.gatsbyImageData}
                    className="h-10 w-10"
                  />
                </div>
                <div>
                  <div className="heading-five">Quickbooks Online</div>
                  <p className="mb-3.5">
                    Access to your QuickBooks online account for your
                    bookkeeping activities.
                  </p>
                  <div className="space-x-1.5 text-sm font-semibold text-secondary-500 transition-colors duration-300 ease-linear group-hover:text-secondary-700">
                    <span>Log In</span>
                    <i className="fa-regular fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    taxPlanning: file(relativePath: { eq: "client-login/tax-planning.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    intuit: file(relativePath: { eq: "client-login/Tax Prep icon.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    quickbooks: file(relativePath: { eq: "client-login/quickbooks.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
    reporting: file(relativePath: { eq: "client-login/reporting.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 40)
      }
    }
  }
`;

export default Page;
